<template>
  <div>
    <a-spin :spinning="loading">
      <div>
        <!-- header -->
        <div style="margin: -45px -24px 0px">
          <a-card :bordered="false">
            <DescriptionList>
              <div style="text-align: right" class="table-operatorTitle">
                <label class="detailTop-edit">
                  <img :src="this.changeFirstImg()" class="icon" />
                  &nbsp;&nbsp;
                  {{ dataquery.storeName }}&nbsp;&nbsp;
                  <div :class="this.changeImgbg()">
                    {{ showStoreStatus(dataquery.storeStatus) }}
                  </div>
                </label>
                <a-button type="primary" @click="onEdit">编辑</a-button>
              </div>
              <div style="margin-left: 54px;width: 90%">
                <Description term="店铺类型" class="first">
                  <label>{{ showStoreType(dataquery.storeType) }}</label>
                </Description>
                <Description term="所属门店" class="first">
                  <label>{{ dataquery.facilityName }}</label>
                </Description>
                <Description term="关联档口" class="first">
                  <label>
                    {{ dataquery.kitchenCode }}
                  </label>
                </Description>
                <Description term="客户名称" class="first">
                  <label>{{ dataquery.tenantName }}</label>
                </Description>
              </div>
            </DescriptionList>
          </a-card>
        </div>
        <!-- 基本信息、证照信息 -->
        <div style="margin-bottom: 24px; margin: -24px -24px 24px">
          <a-card
            style="width:100%"
            :bordered="false"
            :tabList="tabListNoTitle"
            :activeTabKey="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
          >
          </a-card>
        </div>
        <!-- content--1 -->
        <div class="overflow-scroll">
          <div style="min-width: 1000px">
            <div style="margin-left: 16px; margin-bottom: 24px">
              <!-- 基本信息 -->
              <a-card
                v-if="noTitleKey === 'baseDetail'"
                :bordered="false"
                style="margin-left: -16px"
              >
                <a-collapse
                  accordion
                  :bordered="false"
                  defaultActiveKey="1"
                  style="margin-left: -16px"
                >
                  <a-collapse-panel
                    header="基本信息"
                    key="1"
                    style="border: 0;"
                  >
                    <DescriptionList size="large">
                      <div>
                        <Description style="width: 33%" term="店铺使用面积"
                          ><label
                            >{{
                              kitchenBuildingVO.usableArea
                            }}&nbsp;&nbsp;m²</label
                          ></Description
                        >
                        <Description style="width: 33%" term="电量"
                          ><label
                            >{{
                              kitchenBuildingVO.actualElectricity
                            }}&nbsp;&nbsp;Kw
                          </label></Description
                        >
                        <Description style="width: 33%" term="排风量"
                          ><label
                            >{{
                              kitchenBuildingVO.actualExhaust
                            }}&nbsp;&nbsp;m³/h</label
                          ></Description
                        >
                        <Description style="width: 33%" term="燃气"
                          ><label>
                            {{ kitchenBuildingVO.gas }}&nbsp;&nbsp;m³</label
                          >
                        </Description>
                        <Description style="width: 33%" term="饿了么上线日期"
                          ><label>{{
                            dataquery.elemeStoreOpenDate
                          }}</label></Description
                        >
                        <Description style="width: 33%" term="美团上线日期"
                          ><label
                            >{{ dataquery.meituanStoreOpenDate }}
                          </label></Description
                        >
                        <Description style="width: 33%" term="饿了么是否授权"
                          ><label>
                            {{ showApiAuth(dataquery.elemeApiAuth) }}</label
                          ></Description
                        >
                        <Description style="width: 33%" term="美团外卖是否授权"
                          ><label>
                            {{ showApiAuth(dataquery.meituanApiAuth) }}</label
                          >
                        </Description>
                      </div>
                    </DescriptionList>
                  </a-collapse-panel>
                </a-collapse>
              </a-card>
              <!-- 证照信息 -->
            </div>
          </div>
        </div>
        <!--        人员信息-->
        <div style="margin-bottom: 24px;" v-if="noTitleKey === 'baseDetail'">
          <a-card>
            <DescriptionList size="large">
              <a-card :bordered="false">
                <div class="table-operator" style="color: rgba(0, 0, 0, 0.85);">
                  <label class="listTitle">人员信息</label>
                  <a-button type="primary" icon="plus" @click="addStaff"
                    >新增人员</a-button
                  >
                </div>
                <a-table
                  rowKey="id"
                  :pagination="paginationStaff"
                  :columns="columns"
                  :dataSource="staffData"
                  :scroll="{ x: 800 }"
                  @change="changePageSizefacility"
                >
                  <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </span>
                  <!-- <span slot="gender" slot-scope="text, record">
                    {{ changeGender(record.gender) }}
                  </span> -->
                  <span
                    slot="idCardFrontPic"
                    slot-scope="text, record"
                    class="blueColor"
                  >
                    <template>
                      <a @click="idCardFrontPicPre(record)">
                        {{ record.idCardFrontPic ? "正面" : " " }}
                      </a>
                      <a-modal
                        :visible="record.previewidCardFrontPic"
                        :footer="null"
                        @cancel="handleCancel(record)"
                      >
                        <img
                          alt="example"
                          style="width: 100%"
                          :src="record.idCardFrontPic"
                        />
                      </a-modal>
                      <a @click="idCardBackPicPre(record)">{{
                        record.idCardBackPic ? "反面" : " "
                      }}</a>
                      <a-modal
                        :visible="record.previewidCardBackPic"
                        :footer="null"
                        @cancel="handleCancel(record)"
                      >
                        <img
                          alt="example"
                          style="width: 100%"
                          :src="record.idCardBackPic"
                        />
                      </a-modal>
                    </template>
                  </span>
                  <span
                    slot="healthCertificateFrontPic"
                    slot-scope="text, record"
                    class="blueColor"
                  >
                    <template>
                      <a @click="healthCertificateFront(record)">{{
                        record.healthCertificateFrontPic ? "正面" : " "
                      }}</a
                      >&nbsp;&nbsp;
                      <a-modal
                        :visible="record.previewhealthPic"
                        :footer="null"
                        @cancel="handleCancel(record)"
                      >
                        <img
                          alt="example"
                          style="width: 100%"
                          :src="record.healthCertificateFrontPic"
                        />
                      </a-modal>
                      <a @click="healthCertificateBack(record)">{{
                        record.healthCertificateBackPic ? "反面" : " "
                      }}</a>
                      <a-modal
                        :visible="record.previewhealthBackPic"
                        :footer="null"
                        @cancel="handleCancel(record)"
                      >
                        <img
                          alt="example"
                          style="width: 100%"
                          :src="record.healthCertificateBackPic"
                        />
                      </a-modal>
                    </template>
                  </span>
                  <span
                    slot="action"
                    slot-scope="text, record"
                    class="blueColor"
                  >
                    <template>
                      <a @click="editStaff(record)">编辑</a>&nbsp;&nbsp;
                      <a @click="handleDeleteStaff(record)">删除</a>
                    </template>
                  </span>
                </a-table>
              </a-card>
              <a-modal
                title="新建员工"
                :width="640"
                :visible="newvisible"
                :confirmLoading="confirmLoading"
                destroyOnClose
                @ok="handleSubmit"
                @cancel="handleCancel"
              >
                <a-spin :spinning="confirmLoading">
                  <a-form :form="formstaff">
                    <a-form-item
                      label="姓名"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'name',
                          { rules: [{ required: true, message: '请输入' }] }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                    <a-form-item
                      label="性别"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-select
                        v-decorator="[
                          'gender',
                          { rules: [{ required: true, message: '请输入' }] }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in genderList"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      label="职位"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-select
                        @change="controlMana"
                        v-decorator="[
                          'positionId',
                          { rules: [{ required: true, message: '请输入' }] }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in shopPosition"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <div v-if="this.hide == false">
                      <a-form-item
                        label="身份证"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'idCard',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item
                        label="身份证照片"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-upload
                          withCredentials
                          name="multipartFile"
                          listType="picture-card"
                          :fileList="fileList"
                          accept=".png,.jpg"
                          :action="this.uploadUrl()"
                          @preview="handlePreview"
                          @change="handleChange"
                        >
                          <div v-if="fileList.length < 1">
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                              上传正面
                            </div>
                          </div>
                        </a-upload>
                        <a-modal
                          :visible="previewVisible"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage"
                          />
                        </a-modal>
                        <a-upload
                          withCredentials
                          name="multipartFile"
                          listType="picture-card"
                          :fileList="fileList01"
                          accept=".png,.jpg"
                          :action="this.uploadUrl()"
                          @preview="handlePreview01"
                          @change="handleChange01"
                        >
                          <div v-if="fileList01.length < 1">
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                              上传反面
                            </div>
                          </div>
                        </a-upload>
                        <a-modal
                          :visible="previewVisible01"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage01"
                          />
                        </a-modal>
                      </a-form-item>
                      <a-form-item
                        label="健康证起始日期"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-date-picker
                          style="width: 320px"
                          v-decorator="[
                            'healthCertificateStartDate',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item
                        label="健康证结束日期"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-date-picker
                          style="width: 320px"
                          v-decorator="[
                            'healthCertDate',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <span
                        style="
                        position: absolute;
                        top: 527px;
                        left: 76px;
                        font-family: SimSun;
                        line-height: 1;
                        font-size: 14px;
                        color: #f5222d;"
                      >
                        *
                      </span>
                      <a-form-item
                        label="健康证照片"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <div style="position:relative;float:left;">
                          <a-upload
                            withCredentials
                            name="multipartFile"
                            listType="picture-card"
                            :fileList="fileList1"
                            accept=".png,.jpg"
                            :action="this.uploadUrl()"
                            @preview="handlePreview1"
                            @change="handleChange1"
                          >
                            <div v-if="fileList1.length < 1">
                              <a-icon :type="loading ? 'loading' : 'plus'" />
                              <div class="ant-upload-text">
                                上传正面
                              </div>
                            </div>
                          </a-upload>
                          <span
                            v-if="fileList1.length < 1 && isShowTip"
                            style="
                            position: absolute;
                            left: -1px;
                            top: 110px;
                            line-height: 1;
                            font-size: 14px;
                            color: #f5222d;"
                          >
                            请上传
                          </span>
                        </div>
                        <a-modal
                          :visible="previewVisible1"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage1"
                          />
                        </a-modal>
                        <div style="position:relative;float:left;">
                          <a-upload
                            withCredentials
                            name="multipartFile"
                            listType="picture-card"
                            :fileList="fileList11"
                            accept=".png,.jpg"
                            :action="this.uploadUrl()"
                            @preview="handlePreview11"
                            @change="handleChange11"
                          >
                            <div v-if="fileList11.length < 1">
                              <a-icon :type="loading ? 'loading' : 'plus'" />
                              <div class="ant-upload-text">
                                上传反面
                              </div>
                            </div>
                          </a-upload>
                          <span
                            v-if="fileList11.length < 1 && isShowTip1"
                            style="
                            position: absolute;
                            left: 2px;
                            top: 110px;
                            width: 50px;
                            line-height: 1;
                            font-size: 14px;
                            color: #f5222d;"
                          >
                            请上传
                          </span>
                        </div>
                        <a-modal
                          :visible="previewVisible11"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage11"
                          />
                        </a-modal>
                      </a-form-item>
                    </div>
                    <a-form-item
                      label="联系方式"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'phone',
                          { rules: [{ required: true, message: '请输入' }] }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                    <a-form-item
                      v-if="this.hide == false"
                      label="备注"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'remark',
                          { rules: [{ required: false, message: '请输入' }] }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-form>
                </a-spin>
              </a-modal>
              <a-modal
                title="编辑"
                :width="640"
                :visible="staffEdit"
                :confirmLoading="confirmLoading"
                destroyOnClose
                @ok="handleEditStaff"
                @cancel="handleCancel"
              >
                <a-spin :spinning="confirmLoading">
                  <a-form :form="formStaffEdit">
                    <a-form-item
                      label="姓名"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'name',
                          {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: record.name
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                    <a-form-item
                      label="性别"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-select
                        v-decorator="[
                          'gender',
                          {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: getGenderId
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in genderList"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      label="职位"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-select
                        @change="controlMana"
                        v-decorator="[
                          'positionId',
                          {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: record.positionId
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in shopPosition"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <div v-if="this.hide == false">
                      <a-form-item
                        label="身份证"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'idCard',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: record.idCard
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item
                        label="身份证照片"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-upload
                          withCredentials
                          name="multipartFile"
                          listType="picture-card"
                          :fileList="fileList"
                          accept=".png,.jpg"
                          :action="this.uploadUrl()"
                          @preview="handlePreview"
                          @change="handleChange"
                        >
                          <div v-if="fileList.length < 1">
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                              上传正面
                            </div>
                          </div>
                        </a-upload>
                        <a-modal
                          :visible="previewVisible"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage"
                          />
                        </a-modal>
                        <a-upload
                          withCredentials
                          name="multipartFile"
                          listType="picture-card"
                          :fileList="fileList01"
                          accept=".png,.jpg"
                          :action="this.uploadUrl()"
                          @preview="handlePreview01"
                          @change="handleChange01"
                        >
                          <div v-if="fileList01.length < 1">
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                              上传反面
                            </div>
                          </div>
                        </a-upload>
                        <a-modal
                          :visible="previewVisible01"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage01"
                          />
                        </a-modal>
                      </a-form-item>
                      <a-form-item
                        label="健康证起始日期"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-date-picker
                          style="width: 320px"
                          v-decorator="[
                            'healthCertificateStartDate',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue:
                                record.healthCertificateStartDate == ''
                                  ? ''
                                  : moment(record.healthCertificateStartDate)
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item
                        label="健康证结束日期"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-date-picker
                          style="width: 320px"
                          v-decorator="[
                            'healthCertDate',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue:
                                record.healthCertificateDate == ''
                                  ? ''
                                  : moment(record.healthCertificateDate)
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <span
                        style="
                        position: absolute;
                        top: 527px;
                        left: 76px;
                        font-family: SimSun;
                        line-height: 1;
                        font-size: 14px;
                        color: #f5222d;"
                      >
                        *
                      </span>
                      <a-form-item
                        label="健康证照片"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <div style="position:relative;float:left;">
                          <a-upload
                            withCredentials
                            name="multipartFile"
                            listType="picture-card"
                            :fileList="fileList1"
                            accept=".png,.jpg"
                            :action="this.uploadUrl()"
                            @preview="handlePreview1"
                            @change="handleChange1"
                          >
                            <div v-if="fileList1.length < 1">
                              <a-icon :type="loading ? 'loading' : 'plus'" />
                              <div class="ant-upload-text">
                                上传正面
                              </div>
                            </div>
                          </a-upload>
                          <span
                            v-if="fileList1.length < 1 && isShowTip"
                            style="
                            position: absolute;
                            left: -1px;
                            top: 110px;
                            line-height: 1;
                            font-size: 14px;
                            color: #f5222d;"
                          >
                            请上传
                          </span>
                        </div>
                        <a-modal
                          :visible="previewVisible1"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage1"
                          />
                        </a-modal>
                        <div style="position:relative;float:left;">
                          <a-upload
                            withCredentials
                            name="multipartFile"
                            listType="picture-card"
                            :fileList="fileList11"
                            accept=".png,.jpg"
                            :action="this.uploadUrl()"
                            @preview="handlePreview11"
                            @change="handleChange11"
                          >
                            <div v-if="fileList11.length < 1">
                              <a-icon :type="loading ? 'loading' : 'plus'" />
                              <div class="ant-upload-text">
                                上传反面
                              </div>
                            </div>
                          </a-upload>
                          <span
                            v-if="fileList11.length < 1 && isShowTip1"
                            style="
                            position: absolute;
                            left: 2px;
                            top: 110px;
                            width: 50px;
                            line-height: 1;
                            font-size: 14px;
                            color: #f5222d;"
                          >
                            请上传
                          </span>
                        </div>
                        <a-modal
                          :visible="previewVisible11"
                          :footer="null"
                          @cancel="handleCan"
                        >
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage11"
                          />
                        </a-modal>
                      </a-form-item>
                    </div>
                    <a-form-item
                      label="联系方式"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'phone',
                          {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: record.phone
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                    <a-form-item
                      v-if="this.hide == false"
                      label="备注"
                      :labelCol="labelCol"
                      :wrapperCol="wrapperCol"
                    >
                      <a-input
                        v-decorator="[
                          'remark',
                          {
                            rules: [{ required: false, message: '请输入' }],
                            initialValue: record.remark
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-form>
                </a-spin>
              </a-modal>
            </DescriptionList>
          </a-card>
        </div>
        <!--        设备信息-->
        <div style="margin-bottom: 24px;" v-if="noTitleKey === 'baseDetail'">
          <a-card>
            <DescriptionList size="large">
              <a-card :bordered="false">
                <div class="table-operator" style="color: rgba(0, 0, 0, 0.85);">
                  <label class="listTitle">设备列表</label>
                  <a-button type="primary" icon="plus" @click="add"
                    >新增</a-button
                  >
                </div>
                <a-table
                  rowKey="id"
                  :pagination="paginationStaff"
                  :columns="columns1"
                  :dataSource="deviceList"
                  :scroll="{ x: 800 }"
                  @change="changePageSizefacility"
                >
                  <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </span>
                  <span slot="positionId" slot-scope="text, record">
                    {{ changePosition(record.positionId) }}
                  </span>
                  <span
                    slot="action"
                    slot-scope="text, record"
                    class="blueColor"
                  >
                    <template>
                      <a @click="doeditDevice(record)">编辑</a>&nbsp;&nbsp;
                      <a @click="handleDeleteDevice(record)">删除</a>
                    </template>
                  </span>
                </a-table>
                <a-modal
                  title="新增设备"
                  :width="640"
                  :visible="newDevice"
                  :confirmLoading="confirmLoading"
                  destroyOnClose
                  @ok="handledeviceSubmit"
                  @cancel="handleCancel"
                >
                  <a-spin :spinning="confirmLoading">
                    <a-form :form="formDevice">
                      <a-form-item
                        label="设备名称"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'name',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="设备数量"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'num',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="电压/每个设备"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'voltage',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="功率/每个设备"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'power',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="燃气量"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'gasQuantity',
                            { rules: [{ required: true, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="备注"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'remark',
                            { rules: [{ required: false, message: '请输入' }] }
                          ]"
                        />
                      </a-form-item>
                    </a-form>
                  </a-spin>
                </a-modal>
                <a-modal
                  title="编辑设备"
                  :width="640"
                  :visible="editDevice"
                  :confirmLoading="confirmLoading"
                  destroyOnClose
                  @ok="handleEditDevice"
                  @cancel="handleCancel"
                >
                  <a-spin :spinning="confirmLoading">
                    <a-form :form="formEditDevice">
                      <a-form-item
                        label="设备名称"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'name',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: this.record.name
                            }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="设备数量"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'num',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: this.record.num
                            }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="电压/每个设备"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'voltage',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: this.record.voltage
                            }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="功率/每个设备"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'power',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: this.record.power
                            }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="燃气量"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'gasQuantity',
                            {
                              rules: [{ required: true, message: '请输入' }],
                              initialValue: this.record.gasQuantity
                            }
                          ]"
                        />
                      </a-form-item>
                      <a-form-item
                        label="备注"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                        <a-input
                          v-decorator="[
                            'remark',
                            {
                              rules: [{ required: false, message: '请输入' }],
                              initialValue: this.record.remark
                            }
                          ]"
                        />
                      </a-form-item>
                    </a-form>
                  </a-spin>
                </a-modal>
              </a-card>
            </DescriptionList>
          </a-card>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import begin from "@/assets/begin.png";
import close from "@/assets/close.png";
import warn from "@/assets/warn.png";

import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import axios from "axios";

  
import { modal } from "ant-design-vue";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { RoleTypes } from "../../../store/modules/roles";

export default {
  name: "shopListDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      isShowTip: false,
      isShowTip1: false,
      begin,
      warn,
      close,
      record: "",
      fileList: [],
      fileList01: [],
      fileList1: [],
      fileList11: [],
      loading: false,
      previewVisible: false,
      previewVisible01: false,
      previewImage: "",
      previewImage01: "",
      previewVisible1: false,
      previewVisible11: false,
      previewImage1: "",
      previewImage11: "",
      //员工职位
      shopPosition: [
        {
          id: 1,
          name: "管理员"
        },
        {
          id: 2,
          name: "店长"
        },
        {
          id: 3,
          name: "员工"
        }
      ],
      genderList: [
        { id: "M", name: "男" },
        { id: "F", name: "女" }
      ],
      //新增人员的form单
      formstaff: this.$form.createForm(this),
      //编辑人员的form单
      formStaffEdit: this.$form.createForm(this),
      //新增设备的form单
      formDevice: this.$form.createForm(this),
      //编辑设备的form单
      formEditDevice: this.$form.createForm(this),
      //经营品类
      cuisineTypeList: [],
      //店铺名称
      facilityList: [],
      //店铺状态
      storeStatusList: [],
      //店铺类型
      storeTypeList: [],
      //授权
      ApiAuthList: [
        { id: 1, name: "未授权" },
        { id: 2, name: "已授权" }
      ],
      //基本信息
      kitchenBuildingVO: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      dataquery: "",
      form: this.$form.createForm(this),
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
        // {
        //   key: "licenceDetail",
        //   tab: "证照信息"
        // },
      ],
      noTitleKey: "baseDetail",
      paginationStaff: defaultPagination(() => {}),
      //表头
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          // scopedSlots: { customRender: "gender" }
        },
        {
          title: "职位",
          dataIndex: "position",
          key: "position"
          //   scopedSlots: { customRender: "positionId" }
        },
        {
          title: "身份证",
          dataIndex: "idCard",
          key: "idCard"
        },
        {
          title: "身份证照片",
          // dataIndex: "idCardFrontPic",
          key: "idCardFrontPic",
          scopedSlots: { customRender: "idCardFrontPic" }
        },
        {
          title: "健康证有效日期",
          dataIndex: "healthCertificateDate",
          key: "healthCertificateDate"
        },
        {
          title: "健康证照片",
          dataIndex: "healthCertificateFrontPic",
          key: "healthCertificateFrontPic",
          scopedSlots: { customRender: "healthCertificateFrontPic" }
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          key: "phone"
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark"
        },
        {
          title: "操作",
          // dataIndex: "action",
          key: "action",
          fixed: "right",
          width: 130,
          scopedSlots: { customRender: "action" }
        }
      ],
      //表头1
      columns1: [
        {
          title: "设备名称",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "设备数量",
          dataIndex: "num",
          key: "num"
        },

        {
          title: "电压/每个设备",
          dataIndex: "voltage",
          key: "voltage"
        },
        {
          title: "功率/每个设备",
          dataIndex: "power",
          key: "power"
          //   scopedSlots: { customRender: "positionId" }
        },
        {
          title: "燃气量",
          dataIndex: "gasQuantity",
          key: "gasQuantity"
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark"
        },
        {
          title: "操作",
          // dataIndex: "action",
          key: "action",
          fixed: "right",
          width: 130,
          scopedSlots: { customRender: "action" }
        }
      ],
      //人员列表
      staffData: [],
      //设备列表
      deviceList: [],
      //新增人员弹框
      newvisible: false,
      //编辑人员弹框
      staffEdit: false,
      confirmLoading: false,
      // 新增设备弹框
      newDevice: false,
      //编辑设备弹框
      editDevice: false,
      healthCertFrontPicUrl: "",
      healthCertBackPicUrl: "",
      idCardFrontPicUrl: "",
      idCardBackPicUrl: "",
      hide: false
    };
  },
  created: async function() {
    this.loading = true;
    await Promise.all([this.getDetail(), this.getInformation()]);
    this.loading = false;
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/shopList"
      });
    });
  },
  computed: {
    changeGender(value) {
      return function(value) {
        let val = "";
        this.genderList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },
    //经营品类枚举
    showCuisineType(value) {
      return function(value) {
        let val = "";
        this.cuisineTypeList.forEach(item => {
          if (value == item.cuisineType) {
            val = item.cuisineTypeShow;
          }
        });
        return val;
      };
    },
    //店铺状态枚举
    showStoreStatus(value) {
      return function(value) {
        let val = "";
        this.storeStatusList.forEach(item => {
          if (value == item.storeStatus) {
            val = item.storeStatusShow;
          }
        });
        return val;
      };
    },
    //店铺类型枚举
    showStoreType(value) {
      return function(value) {
        let val = "";
        this.storeTypeList.forEach(item => {
          if (value == item.storeType) {
            val = item.storeTypeShow;
          }
        });
        return val;
      };
    },
    //授权
    showApiAuth(value) {
      return function(value) {
        let val = "";
        this.ApiAuthList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },
    getGenderId(){
      if(!this.record.gender) {
        return null;
      } else {
        return this.genderList.find(g=> g.name === this.record.gender).id;
      }
    }
  },
  methods: {
    idCardFrontPicPre(val) {
      val.previewidCardFrontPic = true;
    },
    idCardBackPicPre(val) {
      val.previewidCardBackPic = true;
    },
    healthCertificateFront(val) {
      val.previewhealthPic = true;
    },
    healthCertificateBack(val) {
      val.previewhealthBackPic = true;
    },
    moment,
    handleCan() {
      this.previewVisible = false;
      this.previewVisible01 = false;
      this.previewVisible1 = false;
      this.previewVisible11 = false;
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handlePreview01(file) {
      this.previewImage01 = file.url || file.thumbUrl;
      this.previewVisible01 = true;
    },
    handlePreview1(file) {
      this.previewImage1 = file.url || file.thumbUrl;
      this.previewVisible1 = true;
    },
    handlePreview11(file) {
      this.previewImage11 = file.url || file.thumbUrl;
      this.previewVisible11 = true;
    },
    handleChange({ fileList }) {
      if (fileList.length == 0) {
        this.idCardFrontPicUrl = "";
      }
      fileList.forEach((item, index) => {
        if (item.response && index == 0) {
          this.idCardFrontPicUrl = item.response.obj;
        }
        if (item.status == "error") {
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList = fileList;
    },
    handleChange01({ fileList }) {
      if (fileList.length == 0) {
        this.idCardBackPicUrl = "";
      }
      fileList.forEach((item, index) => {
        if (item.response && index == 0) {
          this.idCardBackPicUrl = item.response.obj;
        }
        if (item.status == "error") {
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList01 = fileList;
    },
    handleChange1({ fileList }) {
      if (fileList.length == 0) {
        this.healthCertFrontPicUrl = "";
      }
      fileList.forEach((item, index) => {
        if (item.response && index == 0) {
          this.healthCertFrontPicUrl = item.response.obj;
        }
        if (item.status == "error") {
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList1 = fileList;
    },
    handleChange11({ fileList }) {
      if (fileList.length == 0) {
        this.healthCertBackPicUrl = "";
      }
      fileList.forEach((item, index) => {
        if (item.response && index == 0) {
          this.healthCertBackPicUrl = item.response.obj;
        }
        if (item.status == "error") {
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList11 = fileList;
    },
    //获取所有枚举信息
    getInformation: async function() {
      return Promise.all([
        this.getCuisineType(),
        this.getFacility(),
        this.getStoreStatus(),
        this.getStoreType()
      ]);
    },
    //经营品类
    getCuisineType() {
      return axios({
        url:   this.$baseUrl + "/tenantStore/allCuisineType",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.cuisineTypeList = res.data.rows;
        }
      });
    },
    // 店铺名称
    getFacility() {
      return axios({
        url:   this.$baseUrl + "/tenantStore/allFacility",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 店铺状态
    getStoreStatus() {
      return axios({
        url:   this.$baseUrl + "/tenantStore/allStoreStatus",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.storeStatusList = res.data.rows;
        }
      });
    },
    // 店铺类型
    getStoreType() {
      return axios({
        url:   this.$baseUrl + "/tenantStore/allStoreType",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.storeTypeList = res.data.rows;
        }
      });
    },
    controlMana(val) {
      if (val) {
        if (val == 1) {
          this.hide = true;
        } else {
          this.hide = false;
        }
      }
    },
    //   header处开业状态的展示
    changeFirstImg() {
      if (
        this.dataquery.storeStatusShow == "施工中" ||
        this.dataquery.storeStatusShow == "已签约"
      ) {
        return warn;
      } else if (this.dataquery.storeStatusShow == "已开业") {
        return begin;
      } else if (
        this.dataquery.storeStatusShow == "停业中" ||
        this.dataquery.storeStatusShow == "已关店"
      ) {
        return close;
      } else {
        return close;
      }
    },
    changeImgbg() {
      if (
        this.dataquery.storeStatusShow == "施工中" ||
        this.dataquery.storeStatusShow == "已签约"
      ) {
        return "abuilding";
      } else if (this.dataquery.storeStatusShow == "开业中") {
        return "open";
      } else if (
        this.dataquery.storeStatusShow == "停业中" ||
        this.dataquery.storeStatusShow == "已关店"
      ) {
        return "close";
      } else {
        return "close";
      }
    },
    //编辑
    onEdit() {
      this.$router.push({
        path: "/role/shopList/shopEdit",
        query: { id: this.dataquery.storeId }
      });
    },
    // 展示时间为年月日
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    // 基本信息、证照信息的切换
    onTabChange(key, type) {
      this[type] = key;
    },
    // 获取详情信息
    getDetail() {
      return axios({
        url:   this.$baseUrl + "tenantStore/detail",
        method: "GET",
        params: {
          storeId: this.$route.query.id
        }
      }).then(res => {
        if (res.data.obj) {
          this.dataquery = res.data.obj;
          this.kitchenBuildingVO = this.dataquery.kitchenBuildingVO;
          this.staffData = this.dataquery.staffList;
          this.staffData.forEach(item => {
            this.$set(item, "previewhealthBackPic", false);
            this.$set(item, "previewhealthPic", false);
            this.$set(item, "previewidCardBackPic", false);
            this.$set(item, "previewidCardFrontPic", false);
            item.healthCertificateStartDate = item.healthCertificateStartDate
              ? moment(item.healthCertificateStartDate).format("YYYY-MM-DD")
              : "";
            item.healthCertificateDate = item.healthCertificateDate
              ? moment(item.healthCertificateDate).format("YYYY-MM-DD")
              : "";
          });
          this.deviceList = this.dataquery.deviceList;
        }
      });
    },

    //新建人员
    addStaff() {
      this.fileList = [];
      this.fileList01 = [];
      this.fileList1 = [];
      this.fileList11 = [];
      this.newvisible = true;
    },
    //调用新增人员接口
    handleSubmit() {
      //点击保存
      let that = this;
      const {
        formstaff: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          if (values.positionId == 1) {
            that.createSaff(values);
          } else {
            if (that.fileList1.length < 1) {
              that.isShowTip = true;
            }
            if (that.fileList1.length == 1) {
              that.isShowTip = false;
            }
            if (that.fileList11.length < 1) {
              that.isShowTip1 = true;
            }
            if (that.fileList11.length == 1) {
              that.isShowTip1 = false;
            }
            if (!that.isShowTip && !that.isShowTip1) {
              that.createSaff(values);
            }
          }
        }
        // if (!errors && !that.isShowTip && !that.isShowTip1) {
        //   that.createSaff(values);
        // }
        this.hide = false;
      });
    },
    // 人员新增接口
    createSaff(val) {
      axios({
        url: "storeStaff/create",
        method: "POST",
        data: {
          ...val,
          storeId: this.dataquery.storeId,
          healthCertFrontPicUrl: this.healthCertFrontPicUrl,
          healthCertBackPicUrl: this.healthCertBackPicUrl,
          idCardFrontPicUrl: this.idCardFrontPicUrl,
          idCardBackPicUrl: this.idCardBackPicUrl
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("人员已建立！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.confirmLoading = false;
        this.newvisible = false;
        this.getDetail();
      });
    },
    //编辑员工
    editStaff(record) {
      this.record = record;
      this.fileList = [];
      this.fileList01 = [];
      this.fileList1 = [];
      this.fileList11 = [];
      if (record.positionId == 1) {
        this.hide = true;
      }
      let a = record.healthCertificateFrontPic
        ? {
            uid: "1",
            name: "xxx.png",
            status: "done",
            url: record.healthCertificateFrontPic || ""
          }
        : "";
      let b = record.healthCertificateBackPic
        ? {
            uid: "2",
            name: "xxx.png",
            status: "done",
            url: record.healthCertificateBackPic || ""
          }
        : "";
      let c = record.idCardFrontPic
        ? {
            uid: "3",
            name: "xxx.png",
            status: "done",
            url: record.idCardFrontPic || ""
          }
        : "";
      let d = record.idCardBackPic
        ? {
            uid: "4",
            name: "xxx.png",
            status: "done",
            url: record.idCardBackPic || ""
          }
        : "";
      if (a) {
        this.fileList1.push(a);
      }
      if (b) {
        this.fileList11.push(b);
      }
      if (c) {
        this.fileList.push(c);
      }
      if (d) {
        this.fileList01.push(d);
      }
      if (this.fileList1[0]) {
        this.healthCertFrontPicUrl = this.fileList1[0].url;
      }
      if (this.fileList11[0]) {
        this.healthCertBackPicUrl = this.fileList11[0].url;
      }
      if (this.fileList[0]) {
        this.idCardFrontPicUrl = this.fileList[0].url;
      }
      if (this.fileList01[0]) {
        this.idCardBackPicUrl = this.fileList01[0].url;
      }
      this.staffEdit = true;
    },
    // 调用编辑员工接口
    handleEditStaff() {
      //点击保存
      let that = this;
      const {
        formStaffEdit: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          if (values.positionId == 1) {
            that.EditSaff(values);
          } else {
            if (that.fileList1.length < 1) {
              that.isShowTip = true;
            }
            if (that.fileList1.length == 1) {
              that.isShowTip = false;
            }
            if (that.fileList11.length < 1) {
              that.isShowTip1 = true;
            }
            if (that.fileList11.length == 1) {
              that.isShowTip1 = false;
            }
            if (!that.isShowTip && !that.isShowTip1) {
              that.EditSaff(values);
            }
          }
        }
      });
    },
    // 人员编辑接口
    EditSaff(val) {
      axios({
        url:   this.$baseUrl + "/storeStaff/modify",
        method: "POST",
        data: {
          ...val,
          id: this.record.id,
          storeStaffRelationId: this.record.storeStaffRelationId,
          storeId: this.dataquery.storeId,
          healthCertFrontPicUrl: this.healthCertFrontPicUrl,
          healthCertBackPicUrl: this.healthCertBackPicUrl,
          idCardFrontPicUrl: this.idCardFrontPicUrl,
          idCardBackPicUrl: this.idCardBackPicUrl
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("编辑成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.confirmLoading = false;
        this.staffEdit = false;
        this.getDetail();
      });
    },
    //删除人员
    handleDeleteStaff(record) {
      let that = this;
      modal.confirm({
        title: "确定要删除该人员吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          axios({
            url:   that.$baseUrl + "storeStaff/remove",
            method: "GET",
            withCredentials: true,
            params: {
              id: record.id,
              storeStaffRelationId: record.storeStaffRelationId,
              storeId: that.dataquery.storeId
            }
          }).then(res => {
            if (res.data.success) {
              that.$message.success("删除成功！");
            } else {
              that.$message.error(`${res.data.returnMsg}`);
            }
            that.getDetail();
          });
        }
      });
    },

    // 新建设备
    add() {
      this.newDevice = true;
    },
    //调用新建设备接口
    handledeviceSubmit() {
      //点击保存
      let that = this;
      const {
        formDevice: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.createDevice(values);
        }
      });
    },
    // 设备新增接口
    createDevice(val) {
      axios({
        url:   this.$baseUrl + "/kitchen/device/create",
        method: "POST",
        data: {
          ...val,
          storeId: this.dataquery.storeId,
          kitchenId: this.dataquery.kitchenId
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("设备已建立！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.confirmLoading = false;
        this.newDevice = false;
        this.getDetail();
      });
    },
    // 编辑设备
    doeditDevice(res) {
      this.record = res;
      this.editDevice = true;
    },
    //调用编辑设备接口
    handleEditDevice() {
      //点击保存
      let that = this;
      const {
        formEditDevice: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.editDeviced(values);
        }
      });
    },
    // 设备编辑接口
    editDeviced(val) {
      axios({
        url:   this.$baseUrl + "/kitchen/device/modify",
        method: "POST",
        data: {
          ...val,
          kitchenId: this.dataquery.kitchenId,
          id: this.record.id
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("编辑成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.confirmLoading = false;
        this.editDevice = false;
        this.getDetail();
      });
    },
    // 删除设备
    handleDeleteDevice(val) {
      let that = this;
      modal.confirm({
        title: "确定要删除该设备吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          axios({
            url:   that.$baseUrl + "/kitchen/device/remove",
            method: "GET",
            withCredentials: true,
            params: {
              deviceId: val.id
            }
          }).then(res => {
            if (res.data.success) {
              that.$message.success("删除成功！");
            } else {
              that.$message.error(`${res.data.returnMsg}`);
            }
            that.getDetail();
          });
        }
      });
    },
    //调用列表接口进行分页
    changePageSizefacility(val) {
      // this.staffList(val.current, val.pageSize);
    },
    handleCancel(val) {
      val.previewhealthBackPic = false;
      val.previewhealthPic = false;
      val.previewidCardBackPic = false;
      val.previewidCardFrontPic = false;
      this.newvisible = false;
      this.staffEdit = false;
      this.newDevice = false;
      this.editDevice = false;
      this.hide = false;
      this.isShowTip = false;
      this.isShowTip1 = false;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-top: -24px;
  text-align: right;
  margin-bottom: 18px;
}
.table-operator button {
  margin-left: 8px;
  margin-right: 0px;
}
.table-operatorTitle {
  margin-bottom: 18px;
}
.height120 .ant-col-md-8 {
  height: 120px;
}
.first {
  font-weight: bolder;
}
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -80px;
}
.ant-carousel >>> .slick-thumb li {
  width: 40px;
  height: 40px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.fee > .ant-col-md-8 {
  width: 25%;
}
</style>
